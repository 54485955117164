<template>
  <div class="invitations-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Customer invitations</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="520">
        <v-text-field
          class="field46 me-4 mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search by name, email or trip"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
        <v-btn @click="modal = true" depressed width="136" large class="primary mt-sm-0 mt-2">Create new</v-btn>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openLink(item.id)" style="height: 64px">
                <td :class="role == 'manager' ? '' : 'primary--text'" class="text-capitalize">
                  <span v-if="item.customer" @click.stop="role == 'manager' ? '' : openCustomer(item.customer.id)">
                    {{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}
                  </span>
                </td>
                <td>
                  {{ item.customer_phone ? flagList.find((i) => i.ct == item.customer_country_code).cl : '' }}{{ item.customer_phone }}
                </td>
                <td>{{ item.customer_email }}</td>
                <td :class="role == 'manager' ? '' : 'primary--text'">
                  <span @click.stop="role == 'manager' ? '' : openTrip(item.trip.id)">{{ item.trip.title }}</span>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openLink(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Customer</div>
              <div
                v-if="item.customer"
                @click.stop="role == 'manager' ? '' : openCustomer(item.customer.id)"
                class="font-weight-medium f18"
                :class="role == 'manager' ? '' : 'primary--text'"
              >
                {{ `${item.customer.first_name} ${item.customer.last_name}` }}
              </div>
              <div v-else></div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Phone</div>
              <div class="font-weight-medium f18">
                {{ item.customer_phone ? flagList.find((i) => i.ct == item.customer_country_code).cl : '' }}{{ item.customer_phone }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Email</div>
              <div class="font-weight-medium f18">{{ item.customer_email }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Trip</div>
              <div class="font-weight-medium f18" :class="role == 'manager' ? '' : 'primary--text'">
                <span @click.stop="role == 'manager' ? '' : openTrip(item.trip.id)">{{ item.trip.title }}</span>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }} trips</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="700">
      <v-card>
        <div class="pt-5 pb-3 text-center">
          <div class="font-weight-bold mb-3">Create invitation</div>
          <v-btn-toggle mandatory class="group-btn" v-model="openWindow">
            <v-btn class="text-body-2" :value="1" @click="(openWindow = 1), (error = [])" outlined height="38">Existing customer</v-btn>
            <v-btn class="text-body-2" :value="2" @click="(openWindow = 2), (error = [])" outlined height="38">New customer </v-btn>
          </v-btn-toggle>
        </div>
        <v-card outlined tile class="pt-4 px-10" v-if="openWindow == 1" min-height="257">
          <div class="mb-4">Select existing Customer from the list</div>
          <v-autocomplete
            class="field46"
            v-model="modalData.customer"
            placeholder="Select customer"
            :error-messages="customerErrors"
            :items="itemsCustomer"
            :search-input.sync="searchCustomer"
            item-text="full_name"
            item-value="id"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-autocomplete>
          <v-autocomplete
            class="field46 mt-n2"
            v-model="modalData.trip"
            placeholder="Select trip"
            :error-messages="tripErrors"
            :items="itemsTrip"
            item-text="title"
            item-value="id"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-autocomplete>
        </v-card>
        <v-card outlined tile class="pt-4 px-10" v-if="openWindow == 2" min-height="257">
          <div class="mb-4">Provide Customer phone number and/or Email address</div>
          <v-text-field
            class="field46"
            v-model="modalData.customer_phone"
            placeholder="Enter phone number"
            :error-messages="phoneErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="15"
          >
            <template v-slot:prepend-inner>
              <div class="d-flex align-center link mt-1" @click="modalFlag = true">
                <img max-width="28" :src="flagImg(modalData.customer_country_code)" :srcset="flagImgSet(modalData.customer_country_code)" />
                <v-card flat tiel width="15" height="20">
                  <v-icon style="font-size: 16px">mdi-chevron-down</v-icon>
                </v-card>
                <div class="ms-1">+{{ flagList.find((item) => item.ct == modalData.customer_country_code).cl }}</div>
              </div>
            </template>
          </v-text-field>
          <v-text-field
            class="field46 mt-n2"
            v-model="modalData.customer_email"
            placeholder="Enter email"
            :error-messages="emailErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-autocomplete
            class="field46 mt-n2"
            v-model="modalData.trip"
            placeholder="Select trip"
            :error-messages="tripErrors"
            :items="itemsTrip"
            item-text="title"
            item-value="id"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-autocomplete>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="create" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
          <v-btn @click="modal = false" depressed class="ms-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="340" v-model="modalFlag">
      <v-card flat class="pa-3" height="380">
        <v-text-field
          @keyup="searchCountry"
          class="field46 mb-2"
          v-model="country"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          maxlength="15"
        ></v-text-field>
        <v-card tile flat height="300" class="view-without-scroll">
          <div v-for="item in requestsFlag" :key="item.ct" class="my-3">
            <div class="d-flex link" @click="(modalData.customer_country_code = item.ct), (modalFlag = false)">
              <img max-width="28" :src="flagImg(item.ct)" :srcset="flagImgSet(item.ct)" />
              <div class="clip">
                <span class="ms-2">{{ item['en'] }}</span>
                <span class="ms-2">(+{{ item.cl }})</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import json from '@/codes.json';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalFlag: false,
      modalData: {
        customer_country_code: 'sa',
      },
      openWindow: 1,
      flagList: json,
      country: '',
      requestsFlag: json,
      search: this.$route.query.src || '',
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Customer', value: 'customer', sortable: false },
        { text: 'Phone', value: 'customer_phone', sortable: false },
        { text: 'Email', value: 'customer_email', sortable: false },
        { text: 'Trip', value: 'trip', sortable: false },
      ],
      searchCustomer: null,
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getCustomerShort');
    this.$store.dispatch('getTripsShort');
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Invitation was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    searchCountry() {
      const value = this.country.toString().toLowerCase();
      this.requestsFlag = this.flagList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    flagImgSet(code) {
      return `
        ${require(`@/assets/icon/flags/2x/img_flag_${code}.png`)} 2x,
        ${require(`@/assets/icon/flags/3x/img_flag_${code}.png`)} 3x
      `;
    },
    openLink(id) {
      this.$router.push(`/invitation/${id}`);
    },
    openCustomer(id) {
      this.$router.push(`/customers/${id}`);
    },
    openTrip(id) {
      this.$router.push(`/trips/${id}/general`);
    },
    async create() {
      this.error = [];
      await this.$store
        .dispatch('createInvitation', this.modalData)
        .then(() => {
          this.notifi('create');
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      this.statusError = null;
      await this.$store
        .dispatch('getInvitationList', {
          search: this.search,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    modal() {
      this.openWindow = 1;
      this.error = [];
      this.modalData = {
        customer_country_code: 'sa',
      };
    },
    modalFlag() {
      if (!this.modalFlag) {
        this.requestsFlag = this.flagList;
        this.country = '';
      }
    },
    searchCustomer(val) {
      if (val) {
        this.$store.dispatch('getCustomerShort', this.searchCustomer).catch((e) => {
          this.error = e.response.data.error;
        });
      } else {
        this.$store.dispatch('getCustomerShort', '').catch((e) => {
          this.error = e.response.data.error;
        });
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.invitationList;
    },
    itemsTrip() {
      return this.$store.getters.tripsShort;
    },
    itemsCustomer() {
      return this.$store.getters.customerShort;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    customerErrors() {
      const errors = [];
      this.error.find((item) => item == 'customer_info__required') && errors.push('Please select customer');
      this.error.find((item) => item == 'customer_info__invalid') && errors.push('Selected customer is not valid');
      return errors;
    },
    tripErrors() {
      const errors = [];
      this.error.find((item) => item == 'trip__required') && errors.push('Please select trip');
      this.error.find((item) => item == 'trip__invalid') && errors.push('Selected trip is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'customer_info__required') && errors.push('Please enter customer info');
      this.error.find((item) => item == 'customer_info__invalid') && errors.push('Provided customer info is not valid');
      this.error.find((item) => item == 'customer_email__required') && errors.push('Please enter email');
      this.error.find((item) => item == 'customer_email__invalid') && errors.push('Provided email is not valid');
      return errors;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'customer_info__required') && errors.push('Please enter customer info');
      this.error.find((item) => item == 'customer_info__invalid') && errors.push('Provided customer info is not valid');
      this.error.find((item) => item == 'customer_phone__required') && errors.push('Please enter phone');
      this.error.find((item) => item == 'customer_phone__invalid') && errors.push('Provided phone is not valid');
      this.error.find((item) => item == 'customer_country_code__invalid__required') && errors.push('Please enter country code');
      this.error.find((item) => item == 'customer_country_code__invalid__invalid') && errors.push('Provided country code is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setInvitationList');
    this.$store.dispatch('setTripsShort');
    this.$store.dispatch('setCustomerShort');
  },
};
</script>

<style lang="scss">
.invitations-list {
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
